// =============================================================================
//
//  Page Designer Layouts
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@import '../../themes/default/components/widgets/layout';

.widget {
    &-category-grid-inner-wrapper,
    &-category-matrix-inner-wrapper,
    &-double-column-inner-wrapper,
    &-accordion-grid-inner-wrapper {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
    }

    &-article-grid-inner-wrapper {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(flex-start);
    }

    // stylelint-disable selector-class-pattern

    &-category-matrix-inner-wrapper {
        &.twoSmallLeft-oneBigRight-twoSmallBottom {
            .widget-category-matrix-main-container {
                padding-bottom: 0;
            }
        }
    }

    // Layouts Default Spacing Top and Bottom Mobile

    &-layout-single-column {
        .layout-top-spacing {
            margin-top: $widget-single-column__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-single-column__margin-bottom;
        }
    }

    &-layout-double-column {
        .layout-top-spacing {
            margin-top: $widget-double-column__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-double-column__margin-bottom;
        }
    }

    &-layout-html-block-column {
        .layout-top-spacing {
            margin-top: $widget-html-block-column__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-html-block-column__margin-bottom;
        }
    }

    &-layout-accordion {
        .layout-top-spacing {
            margin-top: $widget-accordion-layout__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-accordion-layout__margin-bottom;
        }
    }

    &-layout-article-grid {
        .layout-top-spacing {
            margin-top: $widget-article-grid-layout__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-article-grid-layout__margin-bottom;
        }
    }

    &-layout-blog-carousel {
        .layout-top-spacing {
            margin-top: $widget-blog-carousel-layout__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-blog-carousel-layout__margin-bottom;
        }
    }

    &-layout-category-grid {
        .layout-top-spacing {
            margin-top: $widget-category-grid-layout__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-category-grid-layout__margin-bottom;
        }
    }

    &-layout-category-matrix {
        .layout-top-spacing {
            margin-top: $widget-category-matrix-layout__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-category-matrix-layout__margin-bottom;
        }
    }

    &-layout-product-grid {
        .layout-top-spacing {
            margin-top: $widget-product-grid-layout__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-product-grid-layout__margin-bottom;
        }
    }

    // Affiliate Grid Layout Mobile

    &-layout-affiliate-grid {
        .layout-top-spacing {
            margin-top: $widget-affiliate-grid-layout__margin-top;
        }

        .layout-bottom-spacing {
            margin-bottom: $widget-affiliate-grid-layout__margin-bottom;
        }
    }

    // Layouts widths Mobile

    &-four-columns {
        @include flex(0 0 calc(#{$widget-layout-full__width / 2} - #{$widget-four-columns-base__padding / 2}));
    }

    &-three-columns,
    &-two-columns,
    &-accordion-column,
    &-category-matrix-main-container,
    &-category-matrix-side-container,
    &-category-matrix-bottom-container {
        @include flex(0 0 $widget-layout-full__width);
    }

    // Layouts Mobile Full Widths

    &-category-matrix-inner-wrapper {
        margin: $widget-layout-negative-mobile__margin;
    }

    &-layout-category-grid-inner {
        &:not(.has-diagonal-background) {
            .wrapper {
                padding: 0;
            }

            .widget-four-columns {
                @include flex(0 0 calc(#{$widget-layout-full__width / 2} - #{$widget-four-columns-base__padding / 4}));
            }
        }
    }

    // Product Grid Layout Mobile

    &-product-grid {
        &-heading {
            @include get-font('large');
            padding: $widget-product-grid-heading__padding;
            color: $widget-product-grid-heading__color;
            font-weight: $widget-product-grid-heading__font-weight;
            text-align: center;
            text-transform: uppercase;
        }

        &-inner-wrapper {
            .product {
                border: none;
            }

            .experience-hsngassets-producttile {
                border-top: $experience-hsngassets-producttile__border-top;
            }

            // Card View
            @media screen and (max-width: $tablet-width) {
                &.card-view {
                    .experience-region {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
    
                        .experience-hsngassets-producttile {
                            border: none;
                            padding-top: $base-padding * 2;
                            border-bottom: $experience-hsngassets-producttile__border-top;

                            .product {
                                height: 100%;
                            }
                        }

                        .experience-hsngassets-producttile:nth-child(2n+1) {
                            padding-right: $base-padding;
                            border-right: $experience-hsngassets-producttile__border-top;
                        }
    
                        .experience-hsngassets-producttile:nth-child(2n+2) {
                            padding-left: $base-padding;
                        }
                    }
                }
            }
        }
    }

    // Affiliate Grid

    &-affiliate-grid {
        &-heading {
            @include get-font('xlarge');
            padding: $widget-affiliate-grid-heading__padding;
            border-top: $widget-affiliate-grid-heading__border;
            border-bottom: $widget-affiliate-grid-heading__border;
            font-weight: $widget-affiliate-grid-heading__font-weight;
            text-align: center;
        }

        &-inner-wrapper {
            padding-top: $widget-affiliate-grid-inner-wrapper__padding-top;

            .experience {
                &-region {
                    @include flexbox();
                    @include flex-wrap(wrap);
                }

                &-hsngassets-affiliatetile {
                    @include flex(0 0 100%);
                }
            }
        }
    }

    // Article Grid Layout Mobile

    &-article-grid {
        .widget-three-columns {
            padding: $widget-article-grid__padding;
        }
    }

    // Category Grid Layout Mobile

    &-layout-category-grid-inner {
        &.has-diagonal-background {
            padding: $widget-layout-category-grid-inner__padding;
        }

        .widget-category-grid-heading-wrapper {
            color: $widget-category-grid-heading__color;
            text-align: center;
        }
    }

    &-category-grid {
        .widget-four-columns {
            padding: $widget-category-grid__padding;
        }
    }

    &-category-grid-heading-wrapper {
        > h1,
        h2,
        h3 {
            @include get-font('large');
            margin-bottom: $widget-category-grid-heading-wrapper__margin-bottom;
        }
    }

    // Double Column Layout Desktop

    &-double-column {
        .widget-two-columns {
            padding: $widget-double-column__pading;
        }
    }

    // Category Matrix Layout Mobile

    &-category-matrix-main-container {
        padding-bottom: $widget-category-matrix-main-container__padding-bottom;
    }

    &-category-matrix-side-container {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        padding-bottom: $widget-category-matrix-side-container__padding-bottom;

        .widget-category-martrix-column {
            @include flex(0 0 calc(#{$widget-layout-full__width / 2} - #{$widget-columns-base__padding / 4}));

            &:first-child {
                padding-bottom: 0;
            }

            &:last-child {
                padding-top: 0;
            }
        }
    }

    &-category-matrix-bottom-container {
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        padding-top: $widget-category-matrix-bottom-container__padding-top;

        .widget-category-martrix-column {
            @include flex(0 0 calc(#{$widget-layout-full__width / 2} - #{$widget-columns-base__padding / 4}));
        }
    }

    // Blog Carousel Layout Mobile

    &-blog-carousel-container {
        padding: $widget-blog-carousel-container__padding;
    }

    &-blog-carousel-title-wrapper {
        @include get-font('large');
        margin-bottom: $widget-blog-carousel-title-wrapper__margin-bottom;
        color: $widget-blog-carousel-title-wrapper__color;
        font-weight: $bold;
        text-align: center;
        text-transform: uppercase;
    }

    &-blog-subheading-wrapper {
        padding: $widget-blog-carousel-subheading-wrapper__padding;
        border-top: $widget-blog-carousel-subheading-link__border-top;
        text-align: $widget-blog-carousel-subheading-link__text-align;

        .widget-blog-subheading-link {
            @include get-font('xsmall-small');
            padding: 0;
            margin: 0;
            color: $widget-blog-carousel-subheading-link__color;
            text-decoration-color: $widget-blog-carousel-subheading-link__text-decoration-color;
        }
    }

    &-blog-carousel {
        .slick-slide {
            img {
                max-height: $slick-slide__max-height;
            }
        }

        .slick-blog-carousel {
            padding-bottom: 0;
            margin-top: $widget-slick-blog-carousel__margin-top;
        }

        .slick-dots {
            top: $widget-blog-carousel-slick-dots-position__top;
            bottom: auto;
            padding: $widget-blog-carousel-slick-dots__padding;
            margin: 0;
            list-style-type: none;

            li {
                margin: $widget-blog-carousel-slick-dots__margin;
            }

            button {
                display: block;
                width: $widget-blog-carousel-dots__width__height;
                height: $widget-blog-carousel-dots__width__height;
                padding: 0;
                border: 0;
                border-radius: 100%;
                background-color: $widget-blog-carousel-slick-dots-button__background-color;
                text-indent: -999rem;
            }
        }

        li.slick-active button {
            background-color: $widget-blog-carousel-slick-dots-button-active__background-color;
        }
    }
}

@import '../../screens/large/components/widgets/layout';
@import '../../screens/medium-large/components/widgets/layout';
