// =============================================================================
//
//  Page Designer Dynamic Options CSS
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@import '../../themes/default/components/widgets/layout';
@import '../../themes/default/components/widgets/assets';

// Page designer dynamic colors

.pd-color-white {
    color: $white !important;
}

.pd-color-black {
    color: $black !important;
}

.pd-color-dark-blue {
    color: $dark-blue !important;
}

.pd-color-light-blue {
    color: $light-blue !important;
}

.pd-color-grey {
    color: $grey !important;
}

// Page designer dynamic colors mobile

.pd-mobile-color-white {
    color: $white !important;
}

.pd-mobile-color-black {
    color: $black !important;
}

.pd-mobile-color-grey {
    color: $grey !important;
}

.pd-mobile-color-dark-blue {
    color: $dark-blue !important;
}

.pd-mobile-color-light-blue {
    color: $light-blue !important;
}

// Page designer dynamic background colors

.pd-background-white {
    background-color: $white !important;
}

.pd-background-black {
    background-color: $black !important;
}

.pd-background-grey {
    background-color: $grey !important;
}

.pd-background-dark-blue {
    background-color: $dark-blue !important;
}

.pd-background-light-blue {
    background-color: $light-blue !important;
}


// Page Designer Dynimic Font Sizes for Mobile

// stylelint-disable selector-class-pattern

.pd-font-size-11px:not(.widget-html-block) {
    @include get-font('tiny');

    > * {
        @include get-font('tiny');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-12px:not(.widget-html-block) {
    @include get-font('mini');

    > * {
        @include get-font('mini');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-13px:not(.widget-html-block) {
    @include get-font('mini-xsmall');

    > * {
        @include get-font('mini-xsmall');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-14px:not(.widget-html-block) {
    @include get-font('xsmall');

    > * {
        @include get-font('xsmall');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-15px:not(.widget-html-block) {
    @include get-font('xsmall-small');

    > * {
        @include get-font('xsmall-small');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-16px:not(.widget-html-block) {
    @include get-font('small');

    > * {
        @include get-font('small');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-17px:not(.widget-html-block) {
    @include get-font('small-compact');

    > * {
        @include get-font('small-compact');
    }
}

.pd-font-size-19px:not(.widget-html-block) {
    @include get-font('compact-two');

    > * {
        @include get-font('compact-two');
    }
}

.pd-font-size-21px:not(.widget-html-block) {
    @include get-font('medium');

    > * {
        @include get-font('medium');
    }
}

.pd-font-size-23px:not(.widget-html-block) {
    @include get-font('medium-big-two');

    > * {
        @include get-font('medium-big-two');
    }
}

.pd-font-size-25px:not(.widget-html-block) {
    @include get-font('big');

    > * {
        @include get-font('big');
    }
}

.pd-font-size-27px:not(.widget-html-block) {
    @include get-font('large-two');

    > * {
        @include get-font('large-two');
    }
}

.pd-font-size-29px:not(.widget-html-block) {
    @include get-font('large-xlarge');

    > * {
        @include get-font('large-xlarge');
    }
}

.pd-font-size-31px:not(.widget-html-block) {
    @include get-font('xlarge-two');

    > * {
        @include get-font('xlarge-two');
    }
}

.pd-font-size-33px:not(.widget-html-block) {
    @include get-font('huge-second');

    > * {
        @include get-font('huge-second');
    }
}

.pd-font-size-34px:not(.widget-html-block) {
    @include get-font('huge-third');

    > * {
        @include get-font('huge-third');
    }
}

.pd-font-size-35px:not(.widget-html-block) {
    @include get-font('huge-fourth');

    > * {
        @include get-font('huge-fourth');
    }
}

.pd-font-size-37px:not(.widget-html-block) {
    @include get-font('enormous-second');

    > * {
        @include get-font('enormous-second');
    }
}

.pd-font-size-38px:not(.widget-html-block) {
    @include get-font('enormous-gigantic');

    > * {
        @include get-font('enormous-gigantic');
    }
}

.pd-font-size-39px:not(.widget-html-block) {
    @include get-font('enormous-gigantic-second');

    > * {
        @include get-font('enormous-gigantic-second');
    }
}

.widget {
    &-html-block {
        &.pd-color-white {
            * {
                color: $white !important;
            }
        }

        &.pd-color-black {
            * {
                color: $black !important;
            }
        }

        &.pd-color-red {
            * {
                color: $black !important;
            }
        }

        &.pd-color-sunflower-yellow {
            * {
                color: $black !important;
            }
        }

        &.pd-color-ocre {
            * {
                color: $grey !important;
            }
        }

        &.pd-color-light-gold {
            * {
                color: $dark-blue !important;
            }
        }

        &.pd-color-true-green {
            * {
                color: $light-blue !important;
            }
        }

        &.pd-font-size-11px {
            > p,
            ul,
            ol {
                @include get-font('tiny');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-12px {
            > p,
            ul,
            ol {
                @include get-font('mini');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-13px {
            > p,
            ul,
            ol {
                @include get-font('mini-xsmall');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-14px {
            > p,
            ul,
            ol {
                @include get-font('xsmall');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-15px {
            > p,
            ul,
            ol {
                @include get-font('xsmall-small');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-16px {
            > p,
            ul,
            ol {
                @include get-font('small');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-18px {
            > p,
            ul,
            ol {
                @include get-font('compact');
            }
        }

        &.pd-font-size-20px {
            > p,
            ul,
            ol {
                @include get-font('normal');
                line-height: $widget_hmtl_p__line-height;
            }
        }
    }

    // Goal Banner Diagonal Backgrounds

    &-goal-banner-background-wrapper {
        &.pd-diagonal-background-white {
            .pd-diagonal-background-white {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-black {
            .pd-diagonal-background-white {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-true-green {
            .pd-diagonal-background-white {
                background-color: $light-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $light-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $light-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $light-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $light-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $light-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $light-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $light-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-very-light-pink {
            .pd-diagonal-background-white {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-red {
            .pd-diagonal-background-white {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-sunflower-yellow {
            .pd-diagonal-background-white {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-ocre {
            .pd-diagonal-background-white {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-light-gold {
            .pd-diagonal-background-white {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }
    }

    // Category Grid Diagonal Backgrounds

    &-layout-category-grid {
        &.pd-diagonal-background-white {
            .pd-diagonal-background-white {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $light-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-black {
            .pd-diagonal-background-white {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $light-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-true-green {
            .pd-diagonal-background-white {
                background-color: $light-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-blue $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $light-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-blue $widget-layout-category-grid-gradient-background-percentage__first, $light-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $light-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-blue $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $light-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-blue $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $light-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-blue $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $light-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-blue $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $light-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-blue $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $light-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-blue $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-very-light-pink {
            .pd-diagonal-background-white {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $light-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-red {
            .pd-diagonal-background-white {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $light-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-sunflower-yellow {
            .pd-diagonal-background-white {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $light-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-ocre {
            .pd-diagonal-background-white {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $light-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-light-gold {
            .pd-diagonal-background-white {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-true-green {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $light-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-very-light-pink {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-sunflower-yellow {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ocre {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-gold {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }
    }
}

@import '../../screens/large/components/widgets/pageDesignerDynamic';
