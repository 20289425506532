// =============================================================================
//
//  Page Designer Dynamic Options CSS
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Ivan Martac <ivan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) {
    .pd-color-white {
        &.pd-mobile-color-white {
            color: $white !important;
        }
        
        &.pd-mobile-color-black {
            color: $white !important;
        }
        
        &.pd-mobile-color-grey {
            color: $white !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $white !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $white !important;
        }
    }
    
    .pd-color-black {
        &.pd-mobile-color-white {
            color: $black !important;
        }
        
        &.pd-mobile-color-black {
            color: $black !important;
        }
        
        &.pd-mobile-color-grey {
            color: $black !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $black !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $black !important;
        }
    }

    .pd-color-grey {
        &.pd-mobile-color-white {
            color: $grey !important;
        }
        
        &.pd-mobile-color-black {
            color: $grey !important;
        }
        
        &.pd-mobile-color-grey {
            color: $grey !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $grey !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $grey !important;
        }
    }

    .pd-color-light-blue {
        &.pd-mobile-color-white {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-black {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-grey {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $light-blue !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $light-blue !important;
        }
    }
    
    
    .pd-color-dark-blue {
        &.pd-mobile-color-white {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-black {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-grey {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-light-blue {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $dark-blue !important;
        }
    }


    // Page Designer Dynimic Font Sizes for Desktop

    // stylelint-disable selector-class-pattern

    .pd-font-size-18px:not(.widget-html-block) {
        @include get-font('compact');
    
        > * {
            @include get-font('compact');
        }
    
        > p,
        ul,
        ol {
            line-height: $widget_hmtl_p__line-height;
        }
    }
    
    .pd-font-size-20px:not(.widget-html-block) {
        @include get-font('normal');
    
        > * {
            @include get-font('normal');
        }
    
        > p,
        ul,
        ol {
            line-height: $widget_hmtl_p__line-height;
        }
    }
    
    .pd-font-size-22px:not(.widget-html-block) {
        @include get-font('medium-big');
    
        > * {
            @include get-font('medium-big');
        }
    
        > p,
        ul,
        ol {
            line-height: $widget_hmtl_p__line-height;
        }
    }
    
    .pd-font-size-24px:not(.widget-html-block) {
        @include get-font('almost-big');
    
        > * {
            @include get-font('almost-big');
        }
    
        > p,
        ul,
        ol {
            line-height: $widget_hmtl_p__line-height;
        }
    }
    

    .pd-font-size-26px:not(.widget-html-block) {
        @include get-font('large');

        > * {
            @include get-font('large');
        }
    }

    .pd-font-size-30px:not(.widget-html-block) {
        @include get-font('xlarge');

        > * {
            @include get-font('xlarge');
        }
    }

    .pd-font-size-32px:not(.widget-html-block) {
        @include get-font('huge');

        > * {
            @include get-font('huge');
        }
    }

    .pd-font-size-36px:not(.widget-html-block) {
        @include get-font('enormous');
        line-height: 1.1;

        > * {
            @include get-font('enormous');
            line-height: 1.1;
        }
    }

    .pd-font-size-52px:not(.widget-html-block) {
        @include get-font('extra-gigantic');
        line-height: 1.1;

        > * {
            @include get-font('extra-gigantic');
            line-height: 1.1;
        }
    }

    .pd-font-size-64px:not(.widget-html-block) {
        @include get-font('64');
        line-height: 1.1;

        > * {
            @include get-font('64');
            line-height: 1.1;
        }
    }

    .pd-font-size-72px:not(.widget-html-block) {
        @include get-font('72');
        line-height: 1.1;

        > * {
            @include get-font('72');
            line-height: 1.1;
        }
    }

    .pd-font-size-84px:not(.widget-html-block) {
        @include get-font('84');
        line-height: 1.1;

        > * {
            @include get-font('84');
            line-height: 1.1;
        }
    }

    .pd-font-size-96px:not(.widget-html-block) {
        @include get-font('96');
        line-height: 1.1;

        > * {
            @include get-font('96');
            line-height: 1.1;
        }
    }

    .widget {
        &-html-block {
            &.pd-font-size-22px {
                > p {
                    @include get-font('medium-big');
                }
            }
        
            &.pd-font-size-24px {
                > p {
                    @include get-font('almost-big');
                }
            }
        
            &.pd-font-size-25px {
                > p {
                    @include get-font('big');
                }
            }
        
            &.pd-font-size-26px {
                > p {
                    @include get-font('large');
                }
            }
        
            &.pd-font-size-30px {
                > p {
                    @include get-font('xlarge');
                }
            }
        
            &.pd-font-size-32px {
                > p {
                    @include get-font('huge');
                }
            }
        
            &.pd-font-size-36px {
                > p {
                    @include get-font('enormous');
                    line-height: 1.1;
                }
            }
        
            &.pd-font-size-46px {
                > p {
                    @include get-font('gigantic');
                    line-height: 1.1;
                }
            }
        }

        // Goald banner Desktop Dynamic Background Colors

        &-goal-banner-background-wrapper {
            &.pd-diagonal-background-white {
                .pd-diagonal-background-white {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-sunflower-yellow {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-black {
                .pd-diagonal-background-white {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-sunflower-yellow {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-true-green {
                .pd-diagonal-background-white {
                    background-color: $light-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $light-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $light-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $light-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $light-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-sunflower-yellow {
                    background-color: $light-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $light-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $light-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-very-light-pink {
                .pd-diagonal-background-white {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-sunflower-yellow {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-red {
                .pd-diagonal-background-white {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-sunflower-yellow {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-sunflower-yellow {
                .pd-diagonal-background-white {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-sunflower-yellow {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-ocre {
                .pd-diagonal-background-white {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-sunflower-yellow {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-light-gold {
                .pd-diagonal-background-white {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-true-green {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-very-light-pink {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-sunflower-yellow {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ocre {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-gold {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
        }    
    }

}
