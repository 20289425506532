// =============================================================================
//
//  Settings
//
//  @version    0.0.1
//  @package    Star Nutrition
//  @author     Willy Larsson <willy@improove.se>
//
//==============================================================================

// -----------------------------------------------------------------------------
//  Base:
// -----------------------------------------------------------------------------
$base-radius: 2.2rem;
$base-margin: 1rem;
$base-padding: 1rem;
$border: 0.1rem;
$normal-weight: 400;
$bold: 700;

// -----------------------------------------------------------------------------
//  Screen sizes:
// -----------------------------------------------------------------------------
$desktop-width-large: 1300px;
$desktop-width-x-large: 1280px;
$desktop-width: 1024px;
$tablet-width: 768px;

// -----------------------------------------------------------------------------
//  Colors: Use http://chir.ag/projects/name-that-color/ to name colors
// -----------------------------------------------------------------------------

$white: #fff;
$black: #1b1b1b;
$grey: #dadada;
$dark-blue: #010039;
$light-blue: #0062a8;
$greyish-brown: #3e3e3e;
$transparent: transparent;
$violet: #010039;
$brown-grey-two: #a1a1a1;
$dark-footer: #010039;
$violet-footer: #10012f;
$light-violet: #4b4265;
$blue: #0a5d9f;
$darker-gray: #9d9d9d;
$very-light-pink-two: #f2f2f2;
$pale-liliac: #e4e4e7;
$grey-background: #F0EEEB;
$notification-red: #ad3626;

// -----------------------------------------------------------------------------
//  Typography:
// -----------------------------------------------------------------------------

// Font Families
$scene-pro-black: ScenePro-Black;
$scene-pro-bold: ScenePro-Bold;


// Settings:
$font-use-rem: true;

// Font Sizes:
$fontsize-micro: 10;
$lineheight-micro: 1.2;

$fontsize-tiny: 11;
$lineheight-tiny: 1.2;

$fontsize-mini: 12;
$lineheight-mini: 1.2;

$fontsize-mini-xsmall: 13;
$lineheight-mini-xsmall: 1.2;

$fontsize-xsmall: 14;
$lineheight-xsmall: 1.2;

$fontsize-xsmall-small: 15;
$lineheight-xsmall-small: 1.2;

$fontsize-small: 16;
$lineheight-small: 1.2;

$fontsize-small-compact: 17;
$lineheight-small-compact: 1.2;

$fontsize-compact: 18;
$lineheight-compact: 1.2;

$fontsize-compact-two: 19;
$lineheight-compact-two: 1.2;

$fontsize-normal: 20;
$lineheight-normal: 1.2;

$fontsize-medium: 21;
$lineheight-medium: 1.2;

$fontsize-medium-big: 22;
$lineheight-medium-big: 1.2;

$fontsize-medium-big-two: 23;
$lineheight-medium-big-two: 1.2;

$fontsize-almost-big: 24;
$lineheight-almost-big: 1.2;

$fontsize-big: 25;
$lineheight-big: 1.2;

$fontsize-large: 26;
$lineheight-large: 1.2;

$fontsize-large-two: 27;
$lineheight-larg-two: 1.2;

$fontsize-large-xlarge: 29;
$lineheight-large-xlarge: 1.2;

$fontsize-xlarge: 30;
$lineheight-xlarge: 1.2;

$fontsize-xlarge-two: 31;
$lineheight-xlarge-two: 1.2;

$fontsize-huge: 32;
$lineheight-huge: 1.2;

$fontsize-huge-second: 33;
$lineheight-huge-second: 1.2;

$fontsize-huge-third: 34;
$lineheight-huge-third: 1.2;

$fontsize-huge-fourth: 35;
$lineheight-huge-fourth: 1.2;

$fontsize-enormous: 36;
$lineheight-enormous: 1.2;

$fontsize-enormous-second: 37;
$lineheight-enormous-second: 1.2;

$fontsize-enormous-gigantic: 38;
$lineheight-enormous-gigantic: 1.2;

$fontsize-enormous-gigantic-second: 39;
$lineheight-enormous-second: 1.2;

$fontsize-gigantic: 46;
$lineheight-gigantic: 1.2;

$fontsize-extra-gigantic: 52;
$lineheight-extra-gigantic: 1.2;

$fontsize-64: 64;
$lineheight-64: 1.2;

$fontsize-72: 72;
$lineheight-72: 1.2;

$fontsize-84: 84;
$lineheight-84: 1.2;

$fontsize-96: 96;
$lineheight-96: 1.2;
